import React, { useRef, useMemo } from 'react';
import './App.css'; 
import { motion } from 'framer-motion';
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

extend({ OrbitControls });

function FloatingCubes() {
  const mesh = useRef();
  const { viewport } = useThree();
  
  const cubes = useMemo(() => {
    const temp = [];
    for (let i = 0; i < 100; i++) {
      const x = (Math.random() - 0.5) * viewport.width;
      const y = (Math.random() - 0.5) * viewport.height;
      const z = (Math.random() - 0.5) * 10;
      temp.push({ position: [x, y, z], rotation: [Math.random() * Math.PI, Math.random() * Math.PI, 0] });
    }
    return temp;
  }, [viewport]);

  useFrame(() => {
    mesh.current.rotation.x += 0.001;
    mesh.current.rotation.y += 0.001;
  });

  return (
    <group ref={mesh}>
      {cubes.map((cube, idx) => (
        <mesh key={idx} position={cube.position} rotation={cube.rotation}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={new THREE.Color(`hsl(240, ${50 + Math.random() * 50}%, ${30 + Math.random() * 40}%)`)} />
        </mesh>
      ))}
    </group>
  );
}

function App() {
  return (
    <div className="bg-blue-900 text-white min-h-screen">
      <Canvas className="canvasContainer">
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <FloatingCubes />
        <OrbitControls />
      </Canvas>
      <div className="content">

      {/* Header Section */}
      <header className="content text-center py-20">
        <motion.h1
          className="text-6xl font-bold mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          I am Slite.
        </motion.h1>
        <motion.img
          src={require('./image/header.png')}
          alt="Header"
          className="w-40 h-40 rounded-full mx-auto mb-6"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        />
        <motion.p
          className="mt-4 text-lg max-w-2xl mx-auto text-gray-300"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
        >
          I bring three years of experience in social media management to the table, with a proven track record in crafting dynamic marketing strategies that resonate with audiences. As an algorithmic wiz, I'm adept at navigating the complexities of social media platforms, ensuring content not only reaches its intended audience but also sparks meaningful engagement.
        </motion.p>
      </header>

      {/* About Section */}
      <section className="py-20">
        <div className="container mx-auto px-4 flex flex-col items-center lg:flex-row">
          <motion.img
            src={require('./image/web3.png')}
            alt="About"
            className="w-80 h-40 rounded-lg mb d-10 lg:mb-0 lg:mr-10"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          />
          <div>
            <h2 className="text-4xl font-semibold mb-6">I'm a Web3 innovator.</h2>
            <p className="text-lg text-gray-300 mb-4">
            In the realm of web3 marketing, I've carved out a niche as a community grower and a bridge for web2 businesses looking to enter the web3 space. Over two years, I've been the force behind several successful transitions, applying my knowledge of design and platform algorithms to create seamless onboarding experiences and foster robust digital communities.
            </p>
            <motion.a
              href="https://twitter.com/0xSlite"
              className="text-blue-400 hover:text-blue-200"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              @0xSlite
            </motion.a>
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="py-20 bg-blue-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-semibold text-center mb-10">What I'm working on.</h2>
          <motion.div
           className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 place-items-center"
            animate="show"
            variants={{
              hidden: { opacity: 0 },
              show: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.3
                }
              }
            }}
          >
            {/* Example Project 1 */}
            <motion.div
              className="bg-blue-700 rounded-lg p-6"
              variants={{ hidden: { y: 20, opacity: 0 }, show: { y: 0, opacity: 1 } }}
            >
              <div className="flex justify-between items-start">
                <h3 className="text-xl font-semibold mb-2">HypeUnit</h3>
                <a href="https://twitter.com/HypeUnit" target="_blank" rel="noopener noreferrer" aria-label="Project 1 Twitter">
                  <svg className="w-6 h-6 text-blue-400 hover:text-blue-200" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M24 4.557a9.83 9.83 0 01-2.828.775 4.94 4.94 0 002.163-2.723 9.869 9.869 0 01-3.127 1.184 4.925 4.925 0 00-8.379 4.494A13.94 13.94 0 011.671 3.149 4.925 4.925 0 003.75 9.965a4.92 4.92 0 01-2.23-.616v.062a4.926 4.926 0 003.946 4.827 4.93 4.93 0 01-2.224.084 4.927 4.927 0 004.6 3.42 9.875 9.875 0 01-6.1 2.1c-.398 0-.79-.023-1.17-.069a13.945 13.945 0 007.547 2.209c9.057 0 14.01-7.5 14.01-14.009 0-.21-.005-.42-.014-.63A10.005 10.005 0 0024 4.557z"/></svg>
                   </a>
                {/* Twitter icon and link */}
              </div>
              <img src={require("./image/hypeunit.jpg")} alt="Project 1" className="rounded-lg mb-4" />
              <p className="text-gray-300">
              Welcome to Hype Unit, the exclusive community where the world of gaming and blockchain converge. This is the premier destination for trailblazers in the GameFi and GambleFi arenas, as well as aficionados of Web3 gaming innovations. At Hype Unit, members are at the forefront of the digital revolution, pushing the boundaries of what's possible in gaming.
              </p>
            </motion.div>
          
            {/* Additional projects can be added similarly */}
          </motion.div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-semibold mb-10">Reach me.</h2>
          <motion.img
            src={require("./image/23.png")}
            alt="Contact"
            className="w-30 h-13 mx-auto mb-6"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          />
          <p className="text-lg text-gray-300 mb-6">
            I'm always open to discussing product design work or partnership opportunities.
          </p>
          <motion.a
            href="mailto:siteweb3@outlook.com"
            className="text-blue-400 hover:text-blue-200"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
          siteweb3@outlook.com
          </motion.a>
        </div>
      </section>
      
{/* Social Links Section */}
<section className="py-10 bg-blue-900  text-center">
  <div className="flex justify-center space-x-4">
    {/* Twitter Link 1 */}
    <motion.a
      href="https://twitter.com/@hypeunit" // Replace with the actual Twitter link
      className="inline-block"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      animate={{ rotate: 360 }}
      transition={{ loop: Infinity, ease: "linear", duration: 10 }}
    >
      <img
        src={require('./image/hypeunit.jpg')} // Replace with the path to your first image
        alt="Twitter Profile 1"
        className="w-20 h-20 rounded-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      />
    </motion.a>

    {/* Twitter Link 2 */}
    <motion.a
      href="https://twitter.com/@0xSlite" // Replace with the actual Twitter link
      className="inline-block"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      animate={{ rotate: 360 }}
      transition={{ loop: Infinity, ease: "linear", duration: 10 }}
    >
      <img
        src={require('./image/header.png')} // Replace with the path to your second image
        alt="Twitter Profile 2"
        className="w-20 h-20 rounded-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      />
    </motion.a>
  </div>
</section>


    </div>
  </div>
  );
}

export default App;
